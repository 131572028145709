import './AddNewSearcherNetwork.scss';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { boolean, number, object, TypeOf } from 'zod';

import { addSearcherNetworkAsync } from '../../api/searchers.api';
import { networkNamesConfig } from '../../shared/configs/network-names.config';

const searcherNetworkConfigSchema = object({
  networkId: number().min(1, 'Network is required'),
  listen: boolean(),
  submit: boolean(),
  ignoreSimulation: boolean(),
  receiveSignature: boolean(),
});

type ISearcherNetworkConfig = TypeOf<typeof searcherNetworkConfigSchema>;
type AddNewSearcherNetworkProps = {
  primaryAddress: string;
  onSuccess?: () => void;
};

const AddNewSearcherNetwork = (
  props: AddNewSearcherNetworkProps
): JSX.Element => {
  const { primaryAddress } = props;
  const [errorMessage, setErrorMessage] = useState('');

  const addSearcherNetworkConfigQuery = useMutation(
    (network: ISearcherNetworkConfig) => {
      return addSearcherNetworkAsync(primaryAddress, network);
    },
    {
      onSuccess: () => {
        if (props.onSuccess) {
          props.onSuccess();
        }
      },
      onError: (error: AxiosError) => {
        const errorData = error?.response?.data as
          | { message?: string }
          | undefined;

        if (errorData?.message) {
          setErrorMessage(errorData.message);
        } else {
          setErrorMessage('Failed to add new serarcher config');
        }
      },
    }
  );

  const defaultValues: ISearcherNetworkConfig = {
    networkId: 1,
    listen: true,
    submit: true,
    ignoreSimulation: false,
    receiveSignature: false,
  };

  const { control, handleSubmit, formState } = useForm<ISearcherNetworkConfig>({
    resolver: zodResolver(searcherNetworkConfigSchema),
    defaultValues,
  });

  const onSubmitHandler: SubmitHandler<ISearcherNetworkConfig> = (
    values: ISearcherNetworkConfig
  ) => {
    setErrorMessage('');
    addSearcherNetworkConfigQuery.mutate(values);
  };

  return (
    <Box
      className="add-searcher-network-form"
      component="form"
      onSubmit={handleSubmit(onSubmitHandler)}>
      {!!errorMessage?.length && (
        <Alert
          severity="error"
          sx={{ marginBottom: '10px' }}
          onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}
      <Controller
        name="networkId"
        control={control}
        defaultValue={defaultValues.networkId}
        render={({ field: { ref, ...field } }) => (
          <TextField
            select
            fullWidth
            size="small"
            label="Network"
            inputRef={ref}
            error={Boolean(formState.errors.networkId)}
            helperText={formState.errors.networkId?.message}
            {...field}>
            {Object.keys(networkNamesConfig).map((networkId) => {
              return (
                <MenuItem key={networkId} value={+networkId}>
                  {networkNamesConfig[networkId]}
                </MenuItem>
              );
            })}
          </TextField>
        )}
      />
      <FormGroup aria-label="position" row>
        <Controller
          name="listen"
          control={control}
          render={({ field: { ...field } }) => (
            <FormControlLabel
              control={
                <Checkbox defaultChecked={defaultValues.listen} {...field} />
              }
              label="Allow listening"
            />
          )}
        />
        <Controller
          name="submit"
          control={control}
          render={({ field: { ...field } }) => (
            <FormControlLabel
              control={
                <Checkbox defaultChecked={defaultValues.submit} {...field} />
              }
              label="Allow submission"
            />
          )}
        />
        <Controller
          name="ignoreSimulation"
          control={control}
          render={({ field: { ...field } }) => (
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={defaultValues.ignoreSimulation}
                  {...field}
                />
              }
              label="Ignore simulation"
            />
          )}
        />
        <Controller
          name="receiveSignature"
          control={control}
          render={({ field: { ...field } }) => (
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={defaultValues.receiveSignature}
                  {...field}
                />
              }
              label="Send signed target tx"
            />
          )}
        />
      </FormGroup>
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 1, mb: 2 }}>
        Submit
      </Button>
    </Box>
  );
};

export default AddNewSearcherNetwork;
