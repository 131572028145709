import './AddNewSearcherEoa.scss';

import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Box, Button, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';

import { addSearcherEoaAsync } from '../../api/searchers.api';

const searcherEoaConfigSchema = object({
  address: string()
    .min(1, 'Address is required')
    .trim()
    .regex(/^0x[a-fA-F0-9]{40}$/, 'Enter a valid address'),
});

type ISearcherEoaConfig = TypeOf<typeof searcherEoaConfigSchema>;
type AddNewSearcherEoaProps = {
  primaryAddress: string;
  onSuccess?: () => void;
};

const AddNewSearcherEoa = (props: AddNewSearcherEoaProps): JSX.Element => {
  const { primaryAddress } = props;
  const [errorMessage, setErrorMessage] = useState('');

  const addSearcherEoaConfigQuery = useMutation(
    (eoa: ISearcherEoaConfig) => {
      return addSearcherEoaAsync(primaryAddress, eoa);
    },
    {
      onSuccess: () => {
        if (props.onSuccess) {
          props.onSuccess();
        }
      },
      onError: (error: AxiosError) => {
        const errorData = error?.response?.data as
          | { message?: string }
          | undefined;

        if (errorData?.message) {
          setErrorMessage(errorData.message);
        } else {
          setErrorMessage('Failed to add new serarcher config');
        }
      },
    }
  );

  const defaultValues: ISearcherEoaConfig = {
    address: '',
  };

  const { control, handleSubmit, formState } = useForm<ISearcherEoaConfig>({
    resolver: zodResolver(searcherEoaConfigSchema),
    defaultValues,
  });

  const onSubmitHandler: SubmitHandler<ISearcherEoaConfig> = (
    values: ISearcherEoaConfig
  ) => {
    setErrorMessage('');
    addSearcherEoaConfigQuery.mutate(values);
  };

  return (
    <Box
      className="add-searcher-eoa-form"
      component="form"
      onSubmit={handleSubmit(onSubmitHandler)}>
      {!!errorMessage?.length && (
        <Alert
          severity="error"
          sx={{ marginBottom: '10px' }}
          onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}
      <Controller
        name="address"
        control={control}
        defaultValue={defaultValues.address}
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Address"
            margin="normal"
            size="small"
            fullWidth
            type="text"
            error={Boolean(formState.errors.address)}
            helperText={formState.errors.address?.message}
            inputRef={ref}
            {...field}
          />
        )}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 1, mb: 2, maxWidth: '100px' }}>
        Submit
      </Button>
    </Box>
  );
};

export default AddNewSearcherEoa;
