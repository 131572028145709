import { ChainIdEnum } from '../enums/chain-id.enum';

export const networkNamesConfig = {
  [String(ChainIdEnum.eth)]: 'ETH Mainnet',
  [String(ChainIdEnum.goerli)]: 'ETH Goerli',
  [String(ChainIdEnum.aurora)]: 'Aurora',
  [String(ChainIdEnum.pol)]: 'Polygon',
  [String(ChainIdEnum.ava)]: 'Avalanche',
  [String(ChainIdEnum.bsc)]: 'BSC',
  [String(ChainIdEnum.base)]: 'Base',
};
