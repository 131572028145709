import { getApiClient } from 'src/api/base.api';
import { ArbitragesInterface } from 'src/shared/interfaces/arbitrages.interface';

const api = getApiClient();

export const getArbitragesAsync = async (
  page: number,
  rowsPerPage: number,
  chainId: number
): Promise<[ArbitragesInterface[], number]> => {
  const response = await api.get('/arbitrages', {
    params: {
      take: rowsPerPage,
      skip: page * rowsPerPage,
      chainId: chainId || undefined,
    },
  });

  return response.data;
};

export const cleanDatabase = async (chainId: number): Promise<boolean> => {
  const response = await api.post('/arbitrages', { chainId });
  return response.data;
};

export const getPotentialArbitrage = async (): Promise<
  'enabled' | 'disabled'
> => {
  const response = await api.get('/arbitrages/status');

  return response.data;
};

export const setPotentialArbitrage = async (
  status: 'enabled' | 'disabled'
): Promise<void> => {
  await api.post('/arbitrages/status', { status });
};
