import { ConsoleUserInterface } from '../shared/interfaces/console-user.interface';
import { getApiClient } from './base.api';
import { ConsoleUserLoginDto } from './dto/console-user-login.dto';

const api = getApiClient();

export const loginUserAsync = async (
  user: ConsoleUserLoginDto
): Promise<{ access_token: string }> => {
  const response = await api.post('/auth/login', user);
  return response.data;
};

export const logoutUserAsync = async (): Promise<void> => {
  await api.get('/auth/logout');
};

export const getProfileAsync = async (): Promise<ConsoleUserInterface> => {
  const response = await api.get('/auth/profile');
  return response.data;
};
