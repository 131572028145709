import './AddToBlacklist.scss';

import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Box, Button, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';

import { addToBlacklistAsync } from '../../api/broadcasters.api';

const backlistdAddressSchema = object({
  address: string()
    .min(1, 'Address is required')
    .trim()
    .regex(/^0x[a-fA-F0-9]{40}$/, 'Enter a valid address'),
  description: string().optional(),
});

type IBlacklistAddress = TypeOf<typeof backlistdAddressSchema>;
type AddToBlacklistProps = {
  onSuccess?: () => void;
};

const AddToBlacklist = (props: AddToBlacklistProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');

  const addToBlacklistQuery = useMutation(addToBlacklistAsync, {
    onSuccess: () => {
      if (props.onSuccess) {
        props.onSuccess();
      }
    },
    onError: (error: AxiosError) => {
      const errorData = error?.response?.data as
        | { message?: string }
        | undefined;

      if (errorData?.message) {
        setErrorMessage(errorData.message);
      } else {
        setErrorMessage('Failed to add new address to blacklist');
      }
    },
  });

  const defaultValues: IBlacklistAddress = {
    address: '',
    description: '',
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IBlacklistAddress>({
    resolver: zodResolver(backlistdAddressSchema),
    defaultValues,
  });

  const onSubmitHandler: SubmitHandler<IBlacklistAddress> = (
    values: IBlacklistAddress
  ) => {
    setErrorMessage('');
    addToBlacklistQuery.mutate(values);
  };

  return (
    <Box
      className="add-to-blacklist-form"
      component="form"
      onSubmit={handleSubmit(onSubmitHandler)}>
      {!!errorMessage?.length && (
        <Alert
          severity="error"
          sx={{ marginBottom: '10px' }}
          onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}

      <Controller
        name="address"
        control={control}
        defaultValue={defaultValues.address}
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Address"
            margin="normal"
            size="small"
            fullWidth
            type="text"
            error={Boolean(errors.address)}
            helperText={errors.address?.message}
            inputRef={ref}
            {...field}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        defaultValue={defaultValues.description}
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Description"
            margin="normal"
            size="small"
            fullWidth
            type="text"
            error={Boolean(errors.description)}
            helperText={errors.description?.message}
            inputRef={ref}
            multiline
            maxRows={4}
            {...field}
          />
        )}
      />
      <Button type="submit" fullWidth variant="contained">
        Add to blacklist
      </Button>
    </Box>
  );
};

export default AddToBlacklist;
