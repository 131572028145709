import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Box, Button, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
// eslint-disable-next-line no-restricted-imports
import { Wallet } from 'ethers';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';

import { updateBroadcasterEOAAsync } from '../../api/broadcasters.api';
import { BroadcastersInterface } from '../../shared/interfaces/broadcasters.interface';

const updateBroadcasterKolibrioEOAConfigSchema = object({
  address: string()
    .min(1, 'Address is required')
    .trim()
    .regex(/^0x[a-fA-F0-9]{40}$/, 'Enter a valid address'),
  kolibrioEoaAddress: string()
    .nullable()
    .refine((value) => {
      const regex = new RegExp('^0x[a-fA-F0-9]{40}$');
      return !value ? true : regex.test(value);
    }),
  kolibrioEoaPrivateKey: string().min(64).max(64).nullable(),
});

type IUpdateBroadcasterKolibrioEOAConfig = TypeOf<
  typeof updateBroadcasterKolibrioEOAConfigSchema
>;

type EditBroadcasterConfig = {
  onSuccess?: () => void;
  broadcaster: BroadcastersInterface;
};

const EditBroadcasterKolibrioEoa = (
  props: EditBroadcasterConfig
): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');

  const updateBroadcasterEOAConfigQuery = useMutation(
    updateBroadcasterEOAAsync,
    {
      onSuccess: () => {
        if (props.onSuccess) {
          props.onSuccess();
        }
      },
      onError: (error: AxiosError) => {
        const errorData = error?.response?.data as
          | { message?: string }
          | undefined;

        if (errorData?.message) {
          setErrorMessage(errorData.message);
        } else {
          setErrorMessage('Failed to edit broadcaster config');
        }
      },
    }
  );

  const defaultValues: IUpdateBroadcasterKolibrioEOAConfig = {
    ...props.broadcaster,
    kolibrioEoaPrivateKey: null,
    kolibrioEoaAddress: props.broadcaster.kolibrioEoaAddress
      ? props.broadcaster.kolibrioEoaAddress
      : '',
  };

  const { control, handleSubmit, formState, register, getValues, setValue } =
    useForm<IUpdateBroadcasterKolibrioEOAConfig>({
      resolver: zodResolver(updateBroadcasterKolibrioEOAConfigSchema),
      defaultValues,
    });
  const onSubmitHandler: SubmitHandler<IUpdateBroadcasterKolibrioEOAConfig> = (
    values: IUpdateBroadcasterKolibrioEOAConfig
  ) => {
    setErrorMessage('');
    updateBroadcasterEOAConfigQuery.mutate({
      ...values,
    });
  };
  return (
    <Box
      className="edit-broadcaster-form"
      component="form"
      onSubmit={handleSubmit(onSubmitHandler)}>
      {!!errorMessage?.length && (
        <Alert
          severity="error"
          sx={{ marginBottom: '10px' }}
          onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}

      <Controller
        name="kolibrioEoaAddress"
        control={control}
        defaultValue={defaultValues.kolibrioEoaAddress}
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Kolibrio EOA Address"
            margin="normal"
            size="small"
            fullWidth
            type="text"
            disabled={true}
            error={Boolean(formState.errors.kolibrioEoaAddress)}
            inputRef={ref}
            {...field}
            {...register('kolibrioEoaAddress', {
              setValueAs: (v) => {
                return v === '' || !v ? null : v;
              },
            })}
          />
        )}
      />
      <Controller
        name="kolibrioEoaPrivateKey"
        control={control}
        defaultValue={defaultValues.kolibrioEoaPrivateKey}
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Kolibrio EOA Private Key"
            margin="normal"
            size="small"
            fullWidth
            type="text"
            error={Boolean(formState.errors.kolibrioEoaPrivateKey)}
            inputRef={ref}
            {...field}
            {...register('kolibrioEoaPrivateKey', {
              setValueAs: (v) => {
                return v === '' || !v ? null : v;
              },
            })}
            onChange={(value) => {
              try {
                const wallet = new Wallet(value.target.value);
                setValue('kolibrioEoaAddress', wallet.address);
              } catch (e) {
                /* empty */
              }
            }}
          />
        )}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2, maxWidth: '100px' }}>
        Submit
      </Button>
    </Box>
  );
};

export default EditBroadcasterKolibrioEoa;
