import { action, makeObservable, observable } from 'mobx';

import { ConsoleUserInterface } from '../shared/interfaces/console-user.interface';

class AppStore {
  apiUrl = process.env.REACT_APP_API_URL;
  user: ConsoleUserInterface | undefined = undefined;

  constructor() {
    makeObservable(this, {
      apiUrl: observable,
      getApiUrl: action,
      user: observable,
      getUser: action,
      setUser: action,
    });
  }

  getApiUrl = (): string | undefined => this.apiUrl;

  getUser = (): ConsoleUserInterface | undefined => this.user;
  setUser = (user: ConsoleUserInterface | undefined): void => {
    this.user = user;
  };
}

export default new AppStore();
