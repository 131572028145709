import {
  FakeSearcherNetworkInterface,
  FakeSearcherWhitelistInterface,
} from 'src/shared/interfaces/fake-searcher-network.interface';

import { getApiClient } from './base.api';
import { AddAddressToFakeSearcherWhitelustDto } from './dto/add-address-to-fake-searcher-whitelist.dto';
import { UpdateFakeSearcherNetworkDto } from './dto/update-fake-searcher-network.dto';

const api = getApiClient();

export const getNetworksAsync = async (): Promise<
  FakeSearcherNetworkInterface[]
> => {
  const response = await api.get('/fake-searcher/networks');
  return response.data;
};

export const getBalanceAsync = async (networkId: number): Promise<string> => {
  const response = await api.get(
    `/fake-searcher/networks/${networkId}/balance`
  );
  return response.data;
};

export const updateNetworkAsync = async (
  updateFakeSearcherNetworkDto: UpdateFakeSearcherNetworkDto
): Promise<FakeSearcherNetworkInterface> => {
  const response = await api.put(
    '/fake-searcher/networks/' + updateFakeSearcherNetworkDto.networkId,
    updateFakeSearcherNetworkDto
  );
  return response.data;
};

export const addAddressToWhitelistAsync = async (
  networkId: number,
  addAddressToFakeSearcherWhitelustDto: AddAddressToFakeSearcherWhitelustDto
): Promise<FakeSearcherWhitelistInterface> => {
  const response = await api.post(
    `/fake-searcher/networks/${networkId}/addresses`,
    addAddressToFakeSearcherWhitelustDto
  );
  return response.data;
};

export const deleteWhitelistedAddressAsync = async (
  networkId: number,
  address: string
): Promise<void> => {
  const response = await api.delete(
    `/fake-searcher/networks/${networkId}/addresses/${address}`
  );
  return response.data;
};
