export enum ChainNamesEnum {
  'All' = 0,
  'Ethereum' = 1,
  'Goerli' = 5,
  'BSC' = 56,
  'BSC-Test' = 97,
  'Aurora' = 1313161554,
  'Aurora-test' = 1313161555,
  'Polygon' = 137,
  'Avalanche' = 43114,
}
