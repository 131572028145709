import { Box, Typography } from '@mui/material';

const NotFound = (): JSX.Element => {
  return (
    <Box
      sx={{
        marginTop: '60px',
        textAlign: 'center',
      }}>
      <Typography variant="h1" style={{ color: '#606060', fontSize: 80 }}>
        404
      </Typography>
      <div>
        <Typography variant="h2" style={{ color: '#606060', fontSize: 32 }}>
          Page not found
        </Typography>
      </div>
    </Box>
  );
};

export default NotFound;
