import Typography from '@mui/material/Typography';

function Title({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <Typography variant="h1" sx={{ textAlign: 'center' }} gutterBottom>
      {children}
    </Typography>
  );
}

export default Title;
