import './AddNewSearcher.scss';

import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Box, Button, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { object, string, TypeOf } from 'zod';

import { addSearcherAsync } from '../../api/searchers.api';

const searcherConfigSchema = object({
  primaryAddress: string()
    .min(1, 'Address is required')
    .trim()
    .regex(/^0x[a-fA-F0-9]{40}$/, 'Enter a valid address'),
  description: string().optional(),
});

type ISearcherConfig = TypeOf<typeof searcherConfigSchema>;
type AddNewSearcherProps = {
  onSuccess?: () => void;
};

const AddNewSearcher = (props: AddNewSearcherProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');

  const addSearcherConfigQuery = useMutation(addSearcherAsync, {
    onSuccess: () => {
      if (props.onSuccess) {
        props.onSuccess();
      }
    },
    onError: (error: AxiosError) => {
      const errorData = error?.response?.data as
        | { message?: string }
        | undefined;

      if (errorData?.message) {
        setErrorMessage(errorData.message);
      } else {
        setErrorMessage('Failed to add new serarcher config');
      }
    },
  });

  const defaultValues: ISearcherConfig = {
    primaryAddress: '',
    description: '',
  };

  const { control, handleSubmit, formState } = useForm<ISearcherConfig>({
    resolver: zodResolver(searcherConfigSchema),
    defaultValues,
  });

  const onSubmitHandler: SubmitHandler<ISearcherConfig> = (
    values: ISearcherConfig
  ) => {
    setErrorMessage('');
    addSearcherConfigQuery.mutate(
      Object.assign(values, { eoas: [], networks: [] })
    );
  };

  return (
    <Box
      className="add-searcher-form"
      component="form"
      onSubmit={handleSubmit(onSubmitHandler)}>
      {!!errorMessage?.length && (
        <Alert
          severity="error"
          sx={{ marginBottom: '10px' }}
          onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}
      <Controller
        name="primaryAddress"
        control={control}
        defaultValue={defaultValues.primaryAddress}
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Primary address"
            margin="normal"
            size="small"
            fullWidth
            type="text"
            error={Boolean(formState.errors.primaryAddress)}
            helperText={formState.errors.primaryAddress?.message}
            inputRef={ref}
            {...field}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        defaultValue={defaultValues.description}
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Description"
            margin="normal"
            size="small"
            fullWidth
            type="text"
            error={Boolean(formState.errors.description)}
            helperText={formState.errors.description?.message}
            inputRef={ref}
            multiline
            maxRows={4}
            {...field}
          />
        )}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2, maxWidth: '100px' }}>
        Submit
      </Button>
    </Box>
  );
};

export default AddNewSearcher;
