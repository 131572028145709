import { Box, Paper } from '@mui/material';
import Title from 'src/components//Title/Title';
import ArbitragesTable from 'src/components/ArbitragesTable/ArbitragesTables';

const Broadcasters = (): JSX.Element => {
  return (
    <Box
      component={Paper}
      sx={{
        p: 2,
      }}>
      <Title>Potential Arbitrages</Title>
      <ArbitragesTable />
    </Box>
  );
};

export default Broadcasters;
