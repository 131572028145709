import { ContentCopy } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import {
  cleanDatabase,
  getArbitragesAsync,
  getPotentialArbitrage,
  setPotentialArbitrage,
} from 'src/api/arbitrages.api';
import { ChainExplorersEnum } from 'src/shared/enums/chain-explorers.enum';
import { ChainNamesEnum } from 'src/shared/enums/chain-names.enum';

import Copy from '../CopyToClipboard/CopyToClipboard';

const ArbitragesTable = (): JSX.Element => {
  const [chainId, setChainId] = useState(0);
  const [sortLevel, setSortLevel] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [message, setMessage] = useState('');

  const { data, refetch } = useQuery(
    ['arbitrages'],
    async () => {
      return getArbitragesAsync(page, rowsPerPage, chainId);
    },
    { enabled: false }
  );

  const [items, total] = data || [];

  const { data: statusData, refetch: statusRefetch } = useQuery(
    ['status'],
    async () => {
      return getPotentialArbitrage();
    },
    { enabled: false }
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePotentialCheck = async (): Promise<void> => {
    await setPotentialArbitrage(
      statusData === 'disabled' ? 'enabled' : 'disabled'
    );

    statusRefetch();
  };

  useEffect(() => {
    refetch();
  }, [page, rowsPerPage, chainId, refetch]);

  useEffect(() => {
    setPage(0);
  }, [chainId]);

  useEffect(() => {
    statusRefetch();
  }, [statusRefetch]);

  useEffect(() => {
    setPage(0);
  }, [chainId]);

  return (
    <>
      <FormGroup>
        <FormControlLabel
          sx={{ justifyContent: { xs: 'center', sm: 'end' } }}
          control={
            <Switch
              checked={statusData === 'enabled'}
              onChange={handlePotentialCheck}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label={`${
            statusData === 'enabled' ? 'Disable' : 'Enable'
          } potential arbitrage checking`}
        />
      </FormGroup>
      <Stack
        spacing={2}
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'center', sm: 'center' }}
        justifyContent={{ xs: 'center', sm: 'end' }}>
        <Box
          sx={{ color: message.includes('NOT') ? 'red' : 'green', mr: 'auto' }}>
          {message}
        </Box>
        <FormControl sx={{ width: '200px' }}>
          <InputLabel id="select-network-label">Network</InputLabel>
          <Select
            labelId="select-network-label"
            id="select-network"
            value={chainId}
            label="Network"
            onChange={(event) => {
              setChainId(+event.target.value);
            }}
            sx={{ height: '40px' }}>
            {Object.values(ChainNamesEnum)
              .filter((v) => typeof v === 'number')
              .map((v) => (
                <MenuItem key={v} value={v}>
                  {ChainNamesEnum[v as number]}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          disabled={!items?.length}
          sx={{ height: '40px' }}
          onClick={async () => {
            const result = await cleanDatabase(chainId);
            const message = result
              ? 'Message to clean Database was sent. It may take time. Refresh the page later to see the results.'
              : 'Message to clean Database was NOT sent! Debug localy.';

            setMessage(message);
          }}>
          Clean Arbitrage Table
        </Button>
      </Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">ID</TableCell>
              <TableCell>Hash</TableCell>
              <TableCell align="center">Network</TableCell>
              <TableCell
                sx={{ cursor: 'pointer' }}
                align="center"
                onClick={() => {
                  setSortLevel(sortLevel === 1 ? -1 : 1);
                }}>
                Level
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items
              ?.filter(({ chainId: dataChainId }) =>
                chainId ? chainId === dataChainId : true
              )
              .sort((a) => (a.level === 'high' ? -sortLevel : sortLevel))
              .map(({ id, hash, chainId, level }) => (
                <TableRow key={id}>
                  <TableCell align="center">{id}</TableCell>
                  <TableCell>
                    <Link
                      href={`${ChainExplorersEnum[chainId]}tx/${hash}`}
                      target="_blank"
                      rel="noopener"
                      sx={{ mr: '5px' }}>
                      {hash}
                    </Link>
                    <Copy text={hash}>
                      <ContentCopy
                        sx={{ fontSize: 'inherit', verticalAlign: 'middle' }}
                      />
                    </Copy>
                  </TableCell>
                  <TableCell align="center">
                    {ChainNamesEnum[chainId]}
                  </TableCell>
                  <TableCell align="center">{level}</TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell
                sx={{ border: 'none', marginBottom: 0, paddingBottom: 0 }}
                colSpan={8}>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={total || 0}
                  rowsPerPage={rowsPerPage}
                  page={!total || total <= 0 ? 0 : page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default ArbitragesTable;
