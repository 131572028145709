import { ContentCopy } from '@mui/icons-material';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { ConnectedSearchersInterface } from 'src/shared/interfaces/connected-searchers.interface';

import { getConnectedSearchersAsync } from '../../api/connectedSearchers.api';
import { ChainIdEnum } from '../../shared/enums/chain-id.enum';
import Copy from '../CopyToClipboard/CopyToClipboard';
import NoData from '../NoData/NoData';

const ConnectedSearchersWidget = (): JSX.Element => {
  const { data, isLoading, remove } = useQuery(
    ['connectedSearchers'],
    getConnectedSearchersAsync
  );

  useEffect(() => {
    return () => {
      remove();
    };
  }, [remove]);

  let connectionsCount = 0;
  if (!isLoading && !!data) {
    data.forEach((searcher: ConnectedSearchersInterface) => {
      connectionsCount += Object.keys(searcher.connections).length;
    });
  }

  return (
    <Box
      className="connected-searchers-widget"
      component={Paper}
      sx={{
        p: 2,
      }}>
      <Typography variant="h2" sx={{ mx: 2, mb: 2 }}>
        Connected searchers
      </Typography>
      <Box>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Searcher</TableCell>
              <TableCell align="right">Network</TableCell>
              <TableCell align="right">SubscriptionID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && !!data && data.length ? (
              data.map((searcher: ConnectedSearchersInterface) => {
                return Object.keys(searcher.connections).map(
                  (chainId, index) => {
                    const subscriptionId = searcher.connections[chainId];
                    return (
                      <TableRow
                        key={subscriptionId}
                        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        {index === 0 && (
                          <TableCell
                            style={{ verticalAlign: 'top' }}
                            component="th"
                            scope="row"
                            rowSpan={Object.keys(searcher.connections).length}
                            sx={{ whiteSpace: 'nowrap' }}>
                            <Copy text={searcher.primaryAddress}>
                              {searcher.primaryAddress}{' '}
                              <ContentCopy sx={{ fontSize: 'inherit' }} />
                            </Copy>
                          </TableCell>
                        )}
                        <TableCell align="right">
                          {ChainIdEnum[+chainId] || chainId}
                        </TableCell>
                        <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                          <Copy text={subscriptionId}>
                            {
                              subscriptionId /* {subscriptionId.slice(0, 6) + "..." + subscriptionId.slice(-6)*/
                            }{' '}
                            <ContentCopy sx={{ fontSize: 'inherit' }} />
                          </Copy>
                        </TableCell>
                      </TableRow>
                    );
                  }
                );
              })
            ) : (
              <TableRow key={'empty'}>
                <TableCell align="right" colSpan={3}>
                  <NoData sx={{ m: 2 }}>No connections</NoData>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableCell align="right" colSpan={3}>
              Total number of connections: <b>{connectionsCount}</b>
            </TableCell>
          </TableFooter>
        </Table>
      </Box>
    </Box>
  );
};

export default ConnectedSearchersWidget;
