import './AddNewBroadcaster.scss';

import { zodResolver } from '@hookform/resolvers/zod';
import { LockReset } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { generate } from '@wcj/generate-password';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { boolean, object, string, TypeOf } from 'zod';

import { addBroadcasterAsync } from '../../api/broadcasters.api';

const broadcasterConfigSchema = object({
  address: string()
    .min(1, 'Address is required')
    .trim()
    .regex(/^0x[a-fA-F0-9]{40}$/, 'Enter a valid address'),
  token: string().trim().min(8, 'min: 8').max(64, 'max: 64'),
  allowCheckBev: boolean(),
  allowCheckAndSubmitBev: boolean(),
  isActive: boolean().optional(),
  description: string().optional(),
});

type IBroadcasterConfig = TypeOf<typeof broadcasterConfigSchema>;

type AddNewBroadcasterProps = {
  onSuccess?: () => void;
};

const AddNewBroadcaster = (props: AddNewBroadcasterProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');

  const addBroadcasterConfigQuery = useMutation(addBroadcasterAsync, {
    onSuccess: () => {
      if (props.onSuccess) {
        props.onSuccess();
      }
    },
    onError: (error: AxiosError) => {
      const errorData = error?.response?.data as
        | { message?: string }
        | undefined;

      if (errorData?.message) {
        setErrorMessage(errorData.message);
      } else {
        setErrorMessage('Failed to add new broadcaster config');
      }
    },
  });

  const defaultValues: IBroadcasterConfig = {
    address: '',
    token: '',
    description: '',
    allowCheckBev: true,
    allowCheckAndSubmitBev: true,
  };

  const { control, handleSubmit, formState, getValues, setValue } =
    useForm<IBroadcasterConfig>({
      resolver: zodResolver(broadcasterConfigSchema),
      defaultValues,
    });

  const onSubmitHandler: SubmitHandler<IBroadcasterConfig> = (
    values: IBroadcasterConfig
  ) => {
    setErrorMessage('');
    addBroadcasterConfigQuery.mutate(values);
  };

  const generateToken = (): void => {
    let token = generate({ length: 24, special: false, upperCase: false });
    const desc = getValues('description')
      ?.trim()
      ?.replace(/ {2}/g, ' ')
      ?.replace(/ /g, '-')
      ?.replace(/[^a-zA-Z0-9-_]/g, '');

    if (desc) {
      token = desc + '_' + token;
    }

    setValue('token', token);
  };

  return (
    <Box
      className="add-broadcaster-form"
      component="form"
      onSubmit={handleSubmit(onSubmitHandler)}>
      {!!errorMessage?.length && (
        <Alert
          severity="error"
          sx={{ marginBottom: '10px' }}
          onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}
      <Controller
        name="address"
        control={control}
        defaultValue={defaultValues.address}
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Address"
            margin="normal"
            size="small"
            fullWidth
            type="text"
            error={Boolean(formState.errors.address)}
            helperText={formState.errors.address?.message}
            inputRef={ref}
            {...field}
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        defaultValue={defaultValues.description}
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Description"
            margin="normal"
            size="small"
            fullWidth
            type="text"
            error={Boolean(formState.errors.description)}
            helperText={formState.errors.description?.message}
            inputRef={ref}
            multiline
            maxRows={4}
            {...field}
          />
        )}
      />
      <Controller
        name="token"
        control={control}
        defaultValue={defaultValues.token}
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Token"
            margin="normal"
            size="small"
            fullWidth
            type="text"
            error={Boolean(formState.errors.token)}
            helperText={formState.errors.token?.message}
            inputRef={ref}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={generateToken} edge="end">
                    <LockReset />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...field}
          />
        )}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2, maxWidth: '100px' }}>
        Submit
      </Button>
    </Box>
  );
};

export default AddNewBroadcaster;
