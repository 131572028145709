import { Backdrop, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react';
import React from 'react';
import { useCookies } from 'react-cookie';

import { getProfileAsync } from '../api/auth.api';
import { useStores } from '../store/RootStore';

type AuthMiddlewareProps = {
  children: React.ReactElement;
};

const AuthMiddleware: React.FC<AuthMiddlewareProps> = observer(
  ({ children }) => {
    const [cookies] = useCookies(['logged_in']);
    const {
      AppStore: { setUser },
    } = useStores();

    const query = useQuery(['authUser'], getProfileAsync, {
      enabled: !!cookies.logged_in,
      select: (data) => data,
      onSuccess: (data) => {
        setUser(data);
      },
      onError: () => {
        setUser(undefined);
      },
    });

    if (query.isLoading && cookies.logged_in) {
      return (
        <Backdrop open={true}>
          <CircularProgress />
        </Backdrop>
      );
    }

    return children;
  }
);

export default AuthMiddleware;
