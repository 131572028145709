import { Box, BoxProps, Tab, Tabs } from '@mui/material';
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import NotFound from 'src/pages/NotFound/NotFound';

type ITab = {
  label: string;
  linkTo: string;
  component: ReactNode;
};
type TabsWithNavigationProps = BoxProps & {
  tabs: ITab[];
};

const TabsWithNavigation = (props: TabsWithNavigationProps): JSX.Element => {
  const { tabs } = props;
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const tabPath = pathArray[pathArray.length - 1];
  let tabIndex = tabs.findIndex((tab) => tab.linkTo === tabPath);
  tabIndex = tabIndex >= 0 ? +tabIndex : 0;

  const [value, setValue] = useState(tabIndex);

  useEffect(() => {
    setValue(tabIndex);
  }, [tabIndex]);

  const handleChange = (event: SyntheticEvent, newValue: number): void => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          {tabs.map((tab, i) => (
            <Tab
              key={`tab-` + i}
              id={`tab-` + i}
              label={tab.label}
              component={Link}
              to={tab.linkTo}
            />
          ))}
        </Tabs>
      </Box>
      <Box sx={{ px: 2, py: 4 }}>
        <Routes>
          {tabs.map((tab, i) => (
            <Route
              key={'tab-panel-' + i}
              path={'/' + tab.linkTo}
              element={tab.component}
            />
          ))}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default TabsWithNavigation;
