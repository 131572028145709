import {
  AddCircleSharp,
  ContentCopy,
  RemoveCircleOutline,
} from '@mui/icons-material';
import {
  Box,
  BoxProps,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { ConfigSearchersEoasInterface } from 'src/shared/interfaces/config-searchers.interface';

import { deleteSearcherEoaAsync } from '../../api/searchers.api';
import AddNewSearcherEoa from '../AddNewSearcherEoa/AddNewSearcherEoa';
import AreYouSure from '../AreYouSure/AreYouSure';
import Copy from '../CopyToClipboard/CopyToClipboard';
import NoData from '../NoData/NoData';

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
};

type SearcherEoaListProps = BoxProps & {
  primaryAddress: string;
  eoas: ConfigSearchersEoasInterface[];
  onSuccess: () => void;
};

const SearcherEoaList = (props: SearcherEoaListProps): JSX.Element => {
  const { primaryAddress, eoas, onSuccess } = props;

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = (): void => setOpenModal(true);
  const handleClose = (): void => setOpenModal(false);

  const deleteQuery = useMutation(
    (data: Array<string>) => {
      const [primaryAddress, eoaAddress] = data;
      return deleteSearcherEoaAsync(primaryAddress, eoaAddress);
    },
    {
      onSuccess,
    }
  );

  const handleDelete = async (
    primaryAddress: string,
    eoaAddress: string
  ): Promise<void> => {
    deleteQuery.mutate([primaryAddress, eoaAddress]);
  };

  return (
    <Box {...props}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ px: 0, py: 1.5 }}>
              <Typography variant="h4">EOAs</Typography>
            </TableCell>
            <TableCell align="right">
              <IconButton
                edge="end"
                aria-label="add eoa"
                sx={{ p: 0 }}
                onClick={handleOpen}>
                <AddCircleSharp color="primary" />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eoas.length > 0 ? (
            eoas.map((item: ConfigSearchersEoasInterface) => (
              <TableRow
                key={item.address}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell sx={{ p: '5px 0' }}>
                  <Copy text={item.address}>
                    {item.address} <ContentCopy sx={{ fontSize: 'inherit' }} />
                  </Copy>
                </TableCell>
                <TableCell align="right">
                  <AreYouSure
                    onClick={() => handleDelete(primaryAddress, item.address)}>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      sx={{ py: 0, px: 0.2 }}>
                      <RemoveCircleOutline
                        fontSize="small"
                        sx={{ color: '#eb8a8a' }}
                      />
                    </IconButton>
                  </AreYouSure>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableCell colSpan={2}>
              <NoData sx={{ m: 1 }} />
            </TableCell>
          )}
        </TableBody>
      </Table>
      <Modal open={openModal} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Typography variant="h2" align="center" sx={{ mb: 3 }}>
            Add new EOA
          </Typography>
          <AddNewSearcherEoa
            primaryAddress={primaryAddress}
            onSuccess={() => {
              if (onSuccess) {
                onSuccess();
              }
              handleClose();
            }}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default SearcherEoaList;
