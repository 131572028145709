import './EditBroadcasterConfig.scss';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { boolean, nativeEnum, number, object, string, TypeOf } from 'zod';

import { updateBroadcasterAsync } from '../../api/broadcasters.api';
import { BlockbuildersEnum } from '../../shared/enums/blockbuilders.enum';
import { SubmitModeEnum } from '../../shared/enums/submit-mode.enum';
import { BroadcastersInterface } from '../../shared/interfaces/broadcasters.interface';

const updateBroadcasterConfigSchema = object({
  address: string()
    .min(1, 'Address is required')
    .trim()
    .regex(/^0x[a-fA-F0-9]{40}$/, 'Enter a valid address'),
  ofaEnabled: boolean(),
  allowFrontRun: boolean(),
  waitSearcherMs: number().min(65).max(10000).nullable(),
  allowReverts: boolean(),
  publicFallback: boolean(),
  allowUseGateway: boolean(),
  gatewayUserId: string(),
  sub: string(),
  resubmitCount: number().min(2).max(12),
  partnerRefundInfo: object({
    address: string()
      .min(1, 'Address is required')
      .trim()
      .regex(/^0x[a-fA-F0-9]{40}$/, 'Enter a valid address'),
    feePercent: number().min(0).max(100),
  }).array(),
  originatorRefundPercent: string().refine((value) => {
    if (value === '') {
      return false;
    }
    const numberValue = Number(value);
    return numberValue >= 0 && numberValue <= 100;
  }),
  // kolibrioEoaAddress: string()
  //   .nullable()
  //   .refine((value) => {
  //     const regex = new RegExp('^0x[a-fA-F0-9]{40}$');
  //     return !value ? true : regex.test(value);
  //   }),
  // kolibrioEoaPrivateKey: string().min(64).max(64).nullable(),
  mode: nativeEnum(SubmitModeEnum),
  trustedBuilders: nativeEnum(BlockbuildersEnum).array(),
  hidden: boolean(),
  buildersPriorityFeeRevshare: boolean(),
  broadcastDelay: number().min(1).max(3),
})
  .refine(
    (input) => {
      return !(
        input.allowUseGateway &&
        (!input.gatewayUserId || input.gatewayUserId === '')
      );
    },
    {
      message: 'Gateway user Id cant be empty',
      path: ['gatewayUserId'],
    }
  )
  .refine(
    (input) => {
      return (
        input.partnerRefundInfo.reduce(
          (sum, partnerRefundInfo) => sum + partnerRefundInfo.feePercent,
          0
        ) +
          +input.originatorRefundPercent <=
        100
      );
    },
    {
      message: 'Fee percent sum cant be more than 100',
      path: ['partnerRefundInfo[0].feePercent'],
    }
  )
  .refine(
    (input) => {
      return (
        input.partnerRefundInfo.reduce(
          (sum, partnerRefundInfo) => sum + partnerRefundInfo.feePercent,
          0
        ) +
          +input.originatorRefundPercent <=
        100
      );
    },
    {
      message: 'Fee percent sum cant be more than 100',
      path: ['originatorRefundPercent'],
    }
  );

type IUpdateBroadcasterConfig = TypeOf<typeof updateBroadcasterConfigSchema>;

type EditBroadcasterConfig = {
  onSuccess?: () => void;
  broadcaster: BroadcastersInterface;
};

const EditBroadcasterConfig = (props: EditBroadcasterConfig): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');

  const updateBroadcasterConfigQuery = useMutation(updateBroadcasterAsync, {
    onSuccess: () => {
      if (props.onSuccess) {
        props.onSuccess();
      }
    },
    onError: (error: AxiosError) => {
      const errorData = error?.response?.data as
        | { message?: string }
        | undefined;

      if (errorData?.message) {
        setErrorMessage(errorData.message);
      } else {
        setErrorMessage('Failed to edit broadcaster config');
      }
    },
  });

  const defaultValues: IUpdateBroadcasterConfig = {
    ...props.broadcaster,
    originatorRefundPercent: String(props.broadcaster.originatorRefundPercent),
    partnerRefundInfo: props.broadcaster.partnerRefundInfo
      ? props.broadcaster.partnerRefundInfo
      : [],
  };

  const { control, handleSubmit, formState, register, getValues, setValue } =
    useForm<IUpdateBroadcasterConfig>({
      resolver: zodResolver(updateBroadcasterConfigSchema),
      defaultValues,
    });
  const { fields, append, update, prepend, remove, swap, move, insert } =
    useFieldArray({
      control,
      name: 'partnerRefundInfo',
    });
  const onSubmitHandler: SubmitHandler<IUpdateBroadcasterConfig> = (
    values: IUpdateBroadcasterConfig
  ) => {
    setErrorMessage('');
    updateBroadcasterConfigQuery.mutate({
      ...values,
      originatorRefundPercent: Number(
        Number(values.originatorRefundPercent).toFixed(2)
      ),
    });
  };
  return (
    <Box
      className="edit-broadcaster-form"
      component="form"
      onSubmit={handleSubmit(onSubmitHandler)}>
      {!!errorMessage?.length && (
        <Alert
          severity="error"
          sx={{ marginBottom: '10px' }}
          onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}
      <Controller
        name="originatorRefundPercent"
        control={control}
        defaultValue={defaultValues.originatorRefundPercent}
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Originator Refund Percent"
            margin="normal"
            size="small"
            fullWidth
            type="number"
            error={Boolean(formState.errors.originatorRefundPercent)}
            inputRef={ref}
            inputProps={{
              inputMode: 'numeric',
              step: '0.01',
            }}
            {...field}
          />
        )}
      />
      <>
        <Stack
          spacing={2}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'center'}
          marginBottom={'10px'}>
          Add partner
          <Button
            onClick={() => {
              append({
                address: '',
                feePercent: 0,
              });
            }}>
            +
          </Button>
        </Stack>
        {fields.map((partnerInfo, index) => (
          <Stack
            key={index}
            spacing={2}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'center'}
            marginBottom={'10px'}>
            <Controller
              name={`partnerRefundInfo.${index}.address`}
              control={control}
              defaultValue={partnerInfo.address} // make sure to set up defaultValue
              render={({ field: { ref, ...field } }) => (
                <TextField
                  label="Address"
                  size="small"
                  fullWidth
                  error={Boolean(
                    formState.errors.partnerRefundInfo &&
                      formState.errors.partnerRefundInfo[index]?.address
                  )}
                  inputRef={ref}
                  {...field}
                />
              )}
            />
            <Controller
              name={`partnerRefundInfo.${index}.feePercent`}
              control={control}
              defaultValue={partnerInfo.feePercent} // make sure to set up defaultValue
              render={({ field: { ref, ...field } }) => (
                <TextField
                  label="Refund Percent"
                  size="small"
                  fullWidth
                  error={Boolean(
                    formState.errors.partnerRefundInfo &&
                      (formState.errors.partnerRefundInfo[index]?.feePercent ||
                        formState.errors.partnerRefundInfo[0]?.feePercent)
                  )}
                  inputRef={ref}
                  type="number"
                  inputProps={{
                    inputMode: 'numeric',
                    step: '0.01',
                  }}
                  {...field}
                  onChange={(event) => {
                    field.onChange(Number(event.target.value));
                  }}
                />
              )}
            />
            <Button
              onClick={() => {
                remove(index);
              }}>
              -
            </Button>
          </Stack>
        ))}
      </>
      <Controller
        name="gatewayUserId"
        control={control}
        defaultValue={defaultValues.gatewayUserId}
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Gateway User Id"
            margin="normal"
            size="small"
            fullWidth
            type="text"
            error={Boolean(formState.errors.gatewayUserId)}
            inputRef={ref}
            {...field}
          />
        )}
      />
      <Controller
        name="sub"
        control={control}
        defaultValue={defaultValues.sub}
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Referral String"
            margin="normal"
            size="small"
            fullWidth
            type="text"
            error={Boolean(formState.errors.sub)}
            inputRef={ref}
            {...field}
          />
        )}
      />
      <Controller
        name="ofaEnabled"
        control={control}
        defaultValue={defaultValues.ofaEnabled}
        render={({ field: { ref, ...field } }) => (
          <FormControlLabel
            control={
              <Checkbox defaultChecked={defaultValues.ofaEnabled} {...field} />
            }
            label="Ofa Enabled"
          />
        )}
      />
      <Controller
        name="allowReverts"
        control={control}
        defaultValue={defaultValues.allowReverts}
        render={({ field: { ref, ...field } }) => (
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={defaultValues.allowReverts}
                {...field}
              />
            }
            label="Allow Reverts"
          />
        )}
      />
      <Controller
        name="allowFrontRun"
        control={control}
        defaultValue={defaultValues.allowFrontRun}
        render={({ field: { ref, ...field } }) => (
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={defaultValues.allowFrontRun}
                {...field}
              />
            }
            label="Allow FrontRun"
          />
        )}
      />
      <Controller
        name="publicFallback"
        control={control}
        defaultValue={defaultValues.publicFallback}
        render={({ field: { ref, ...field } }) => (
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={defaultValues.publicFallback}
                {...field}
              />
            }
            label="Public Fallback"
          />
        )}
      />
      <Controller
        name="allowUseGateway"
        control={control}
        defaultValue={defaultValues.allowUseGateway}
        render={({ field: { ref, ...field } }) => (
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={defaultValues.allowUseGateway}
                {...field}
              />
            }
            label="Allow Use Gateway"
          />
        )}
      />
      <Controller
        name="waitSearcherMs"
        control={control}
        defaultValue={defaultValues.waitSearcherMs}
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Wait Searcher Ms"
            margin="normal"
            size="small"
            fullWidth
            type="number"
            error={Boolean(formState.errors.waitSearcherMs)}
            inputRef={ref}
            {...field}
            {...register('waitSearcherMs', {
              setValueAs: (v) => (v === '' || !v ? null : parseInt(v, 10)),
            })}
          />
        )}
      />
      <Controller
        name="resubmitCount"
        control={control}
        defaultValue={defaultValues.resubmitCount}
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Resubmit Count"
            margin="normal"
            size="small"
            fullWidth
            type="number"
            error={Boolean(formState.errors.resubmitCount)}
            inputRef={ref}
            {...field}
            {...register('resubmitCount', {
              setValueAs: (v) => parseInt(v, 10),
            })}
          />
        )}
      />
      <Controller
        name="broadcastDelay"
        control={control}
        defaultValue={defaultValues.broadcastDelay}
        render={({ field: { ref, ...field } }) => (
          <TextField
            label="Broadcast Delay"
            margin="normal"
            size="small"
            fullWidth
            type="number"
            error={Boolean(formState.errors.broadcastDelay)}
            inputRef={ref}
            {...field}
            {...register('broadcastDelay', {
              setValueAs: (v) => parseInt(v, 10),
            })}
          />
        )}
      />
      <Grid container>
        <Controller
          name="mode"
          control={control}
          defaultValue={defaultValues.mode}
          render={({ field: { ref, ...field } }) => (
            <FormControl variant="outlined">
              <InputLabel id="mode-label">Mode</InputLabel>
              <Select
                variant="outlined"
                value={field.value}
                labelId="mode-label"
                label={'Mode'}
                onChange={field.onChange}>
                {(
                  Object.keys(SubmitModeEnum) as Array<
                    keyof typeof SubmitModeEnum
                  >
                ).map((key) => {
                  return (
                    <MenuItem key={key} value={key}>
                      {SubmitModeEnum[key]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          name="hidden"
          control={control}
          defaultValue={defaultValues.hidden}
          render={({ field: { ref, ...field } }) => (
            <FormControlLabel
              control={
                <Checkbox defaultChecked={defaultValues.hidden} {...field} />
              }
              label="Hidden"
              sx={{ ml: '10px' }}
            />
          )}
        />
        <Controller
          name="buildersPriorityFeeRevshare"
          control={control}
          defaultValue={defaultValues.buildersPriorityFeeRevshare}
          render={({ field: { ref, ...field } }) => (
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={defaultValues.buildersPriorityFeeRevshare}
                  {...field}
                />
              }
              label="Enable Builders Priority Fee Revshare"
            />
          )}
        />
      </Grid>
      <div>Trusted Builders:</div>
      <Controller
        name="trustedBuilders"
        control={control}
        defaultValue={defaultValues.trustedBuilders}
        render={({ field: { ref, ...field } }) => (
          <div>
            {(
              Object.keys(BlockbuildersEnum) as Array<
                keyof typeof BlockbuildersEnum
              >
            ).map((key) => {
              return (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      value={BlockbuildersEnum[key]}
                      checked={field.value.includes(BlockbuildersEnum[key])}
                      onChange={(e) => {
                        field.onChange(field.value);
                      }}
                      onClick={() => {
                        field.value.includes(BlockbuildersEnum[key])
                          ? (field.value = field.value.filter(
                              (builder) => builder !== BlockbuildersEnum[key]
                            ))
                          : field.value.push(BlockbuildersEnum[key]);
                      }}
                    />
                  }
                  label={BlockbuildersEnum[key]}
                />
              );
            })}
          </div>
        )}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2, maxWidth: '100px' }}>
        Submit
      </Button>
    </Box>
  );
};

export default EditBroadcasterConfig;
