import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ConsoleUserLoginDto } from 'src/api/dto/console-user-login.dto';

import { getProfileAsync, loginUserAsync } from '../../api/auth.api';

export const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const from = (location.state?.from.pathname as string) || '/';

  // API Get Current Logged-in user
  const query = useQuery(['authUser'], getProfileAsync, {
    enabled: false,
    select: (data) => data,
    retry: 1,
  });

  //  API Login Mutation
  const { mutate: loginUser } = useMutation(
    (userData: ConsoleUserLoginDto) => loginUserAsync(userData),
    {
      onSuccess: async () => {
        await query.refetch();
        toast.success('You successfully logged in');
        navigate(from);
      },
    }
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    loginUser({
      login: data.get('login') as string,
      password: data.get('password') as string,
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="login"
            label="Username"
            name="login"
            autoComplete="login"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}>
            LogIn
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
