import './SearcherList.scss';

import {
  ContentCopy,
  Delete,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Box,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { BoxProps } from '@mui/system';
import Identicon from '@polkadot/react-identicon';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import {
  ConfigSearchersEoasInterface,
  ConfigSearchersInterface,
} from 'src/shared/interfaces/config-searchers.interface';

import {
  deleteSearcherAsync,
  getSearchersAsync,
} from '../../api/searchers.api';
import AddNewSearcherButton from '../AddNewSearcherButton/AddNewSearcherButton';
import AreYouSure from '../AreYouSure/AreYouSure';
import Copy from '../CopyToClipboard/CopyToClipboard';
import NoData from '../NoData/NoData';
import SearcherEoaList from '../SearcherEoaList/SearcherEoaList';
import SearcherNetworkList from '../SearcherNetworkList/SearcherNetworkList';
import SearchInput from '../SearchInput/SearchInput';

type RowProps = {
  row: ConfigSearchersInterface;
  onDelete: (primaryAddress: string) => void;
  onSuccess: () => void;
};

const Row = (props: RowProps): JSX.Element => {
  const { row, onDelete, onSuccess } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow className="collapsible-row">
        <TableCell sx={{ px: 1.5, pr: 0, py: 0, m: 0 }}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell width={70}>
          <Avatar alt={row.primaryAddress} sx={{ textAlign: 'center' }}>
            <Identicon
              value={row.primaryAddress}
              size={38}
              theme={'ethereum'}
            />
          </Avatar>
        </TableCell>
        <TableCell
          sx={{
            width: '400px',
          }}>
          <Copy text={row.primaryAddress}>
            {row.primaryAddress} <ContentCopy sx={{ fontSize: 'inherit' }} />
          </Copy>
        </TableCell>
        <TableCell
          sx={{
            maxWidth: '300px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}>
          {row.description}
        </TableCell>
        <TableCell align="center">{row.eoas.length}</TableCell>
        <TableCell align="center">{row.networks.length}</TableCell>
        <TableCell align="right">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <AreYouSure onClick={() => onDelete(row.primaryAddress)}>
              <IconButton edge="end" sx={{ m: 0 }}>
                <Delete />
              </IconButton>
            </AreYouSure>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={4}>
                <Grid item xs={12} md={6}>
                  <SearcherEoaList
                    primaryAddress={row.primaryAddress}
                    eoas={row.eoas}
                    onSuccess={onSuccess}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <SearcherNetworkList
                    primaryAddress={row.primaryAddress}
                    networks={row.networks}
                    onSuccess={onSuccess}
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const SearcherList = (props: BoxProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');
  const [searchText, setSearchText] = useState('');

  const { data, isLoading, refetch, remove } = useQuery(
    ['searchers'],
    getSearchersAsync
  );

  useEffect(() => {
    return () => {
      remove();
    };
  }, [remove]);

  const deleteQuery = useMutation(deleteSearcherAsync, {
    onSuccess: () => {
      refetch();
    },
    onError: () => {
      setErrorMessage('Failed to remove broadcaster config');
    },
  });

  const handleDelete = async (address: string): Promise<void> => {
    setErrorMessage('');
    deleteQuery.mutate(address);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value.toLowerCase());
  };

  let filtredData: ConfigSearchersInterface[] = [];
  if (!isLoading && !!data) {
    filtredData = data.filter(
      (item: ConfigSearchersInterface) =>
        item.primaryAddress.toLowerCase().indexOf(searchText) >= 0 ||
        (item.description || '').toLowerCase().indexOf(searchText) >= 0 ||
        item.eoas.some(
          (eoa: ConfigSearchersEoasInterface) =>
            eoa.address.toLowerCase().indexOf(searchText) >= 0
        )
    );
  }

  return (
    <Box className="searchers-list" {...props}>
      {!!errorMessage?.length && (
        <Alert
          severity="error"
          sx={{ mb: '10px' }}
          onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}
      <Box
        display="flex"
        justifyContent={'space-between'}
        sx={{
          mb: 1,
        }}>
        <AddNewSearcherButton
          size="small"
          variant="contained"
          onSuccess={refetch}
          sx={{
            minWidth: '100px',
          }}
        />
        <SearchInput
          size="small"
          sx={{
            width: {
              md: '300px',
              xs: '100%',
            },
          }}
          onChange={handleSearch}
        />
      </Box>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '50px' }} />
              <TableCell colSpan={2}>Primary address</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="center">EOAs</TableCell>
              <TableCell align="center">Allowed networks</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filtredData.length > 0 ? (
              filtredData.map((row: ConfigSearchersInterface) => (
                <Row
                  key={row.primaryAddress}
                  row={row}
                  onDelete={handleDelete}
                  onSuccess={refetch}
                />
              ))
            ) : (
              <TableCell colSpan={7}>
                <NoData sx={{ m: 1 }} />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SearcherList;
