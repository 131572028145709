import { AddCircleSharp, RemoveCircleOutline } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  Checkbox,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, useState } from 'react';
import { UpdateSearcherNetworksDto } from 'src/api/dto/update-searcher.dto';

import {
  deleteSearcherNetworkAsync,
  updateSearcherNetworkAsync,
} from '../../api/searchers.api';
import { networkNamesConfig } from '../../shared/configs/network-names.config';
import { ConfigSearchersNetworksInterface } from '../../shared/interfaces/config-searchers.interface';
import AddNewSearcherNetwork from '../AddNewSearcherNetwork/AddNewSearcherNetwork';
import AreYouSure from '../AreYouSure/AreYouSure';
import NoData from '../NoData/NoData';

const tdStype = {
  px: 0,
  py: 0.5,
  lineHeight: 'normal',
};

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: '300px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
};

type SearcherNetworkListProps = BoxProps & {
  primaryAddress: string;
  networks: ConfigSearchersNetworksInterface[];
  onSuccess: () => void;
};

const SearcherNetworkList = (props: SearcherNetworkListProps): JSX.Element => {
  const { primaryAddress, networks, onSuccess } = props;

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = (): void => setOpenModal(true);
  const handleClose = (): void => setOpenModal(false);

  const deleteQuery = useMutation(
    (data: [string, number]) => {
      const [primaryAddress, networkId] = data;
      return deleteSearcherNetworkAsync(primaryAddress, networkId);
    },
    {
      onSuccess,
    }
  );

  const updateSearcherNetworkConfigQuery = useMutation(
    (network: UpdateSearcherNetworksDto) => {
      return updateSearcherNetworkAsync(primaryAddress, network);
    },
    {
      onSuccess,
    }
  );

  const handleDelete = async (
    primaryAddress: string,
    networkId: number
  ): Promise<void> => {
    deleteQuery.mutate([primaryAddress, networkId]);
  };
  const onSwitchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, checked, value } = event.target;
    updateSearcherNetworkConfigQuery.mutate({
      networkId: Number(value),
      [name]: checked,
    });
  };

  return (
    <Box {...props}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ ...tdStype }}>
              <Typography variant="h4">Networks</Typography>
            </TableCell>
            <TableCell sx={{ width: '90px', ...tdStype }} align="center">
              Allow listening
            </TableCell>
            <TableCell sx={{ width: '90px', ...tdStype }} align="center">
              Allow submission
            </TableCell>
            <TableCell sx={{ width: '90px', ...tdStype }} align="center">
              Ignore simulation
            </TableCell>
            <TableCell sx={{ width: '90px', ...tdStype }} align="center">
              Send signed target tx
            </TableCell>
            <TableCell align="right">
              <IconButton
                edge="end"
                aria-label="add eoa"
                sx={{ p: 0 }}
                onClick={handleOpen}>
                <AddCircleSharp color="primary" />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {networks.length > 0 ? (
            networks.map((item: ConfigSearchersNetworksInterface) => (
              <TableRow
                key={item.networkId}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell sx={{ ...tdStype }}>
                  {networkNamesConfig[item.networkId]}
                </TableCell>
                <TableCell sx={{ ...tdStype }} align="center">
                  <Checkbox
                    checked={item.listen}
                    onChange={onSwitchChange}
                    name={'listen'}
                    value={item.networkId}
                    size={'small'}
                    sx={{ p: 0 }}
                  />
                </TableCell>
                <TableCell sx={{ ...tdStype }} align="center">
                  <Checkbox
                    checked={item.submit}
                    onChange={onSwitchChange}
                    name={'submit'}
                    value={item.networkId}
                    size={'small'}
                    sx={{ p: 0 }}
                  />
                </TableCell>
                <TableCell sx={{ ...tdStype }} align="center">
                  <Checkbox
                    checked={item.ignoreSimulation}
                    onChange={onSwitchChange}
                    name={'ignoreSimulation'}
                    value={item.networkId}
                    size={'small'}
                    sx={{ p: 0 }}
                  />
                </TableCell>
                <TableCell sx={{ ...tdStype }} align="center">
                  <Checkbox
                    checked={item.receiveSignature}
                    onChange={onSwitchChange}
                    name={'receiveSignature'}
                    value={item.networkId}
                    size={'small'}
                    sx={{ p: 0 }}
                  />
                </TableCell>
                <TableCell align="right">
                  <AreYouSure
                    onClick={() =>
                      handleDelete(primaryAddress, item.networkId)
                    }>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      sx={{ py: 0, px: 0.2 }}>
                      <RemoveCircleOutline
                        fontSize="small"
                        sx={{ color: '#eb8a8a' }}
                      />
                    </IconButton>
                  </AreYouSure>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableCell colSpan={6}>
              <NoData sx={{ m: 1 }} />
            </TableCell>
          )}
        </TableBody>
      </Table>
      <Modal open={openModal} onClose={handleClose}>
        <Box sx={modalStyle}>
          <Typography variant="h2" align="center" sx={{ mb: 3 }}>
            Add network access
          </Typography>
          <AddNewSearcherNetwork
            primaryAddress={primaryAddress}
            onSuccess={() => {
              if (onSuccess) {
                onSuccess();
              }
              handleClose();
            }}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default SearcherNetworkList;
