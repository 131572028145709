import {
  ConfigSearchersEoasInterface,
  ConfigSearchersInterface,
  ConfigSearchersNetworksInterface,
} from '../shared/interfaces/config-searchers.interface';
import { getApiClient } from './base.api';
import {
  AddSearcherDto,
  AddSearcherEoaDto,
  AddSearcherNetworksDto,
} from './dto/add-searcher.dto';
import {
  UpdateSearcherDto,
  UpdateSearcherNetworksDto,
} from './dto/update-searcher.dto';

const api = getApiClient();

export const getSearchersAsync = async (): Promise<
  ConfigSearchersInterface[]
> => {
  const response = await api.get('/searchers');
  return response.data;
};

export const addSearcherAsync = async (
  searcher: AddSearcherDto
): Promise<ConfigSearchersInterface> => {
  const response = await api.post('/searchers', searcher);
  return response.data;
};

export const updateSearcherAsync = async (
  searcher: UpdateSearcherDto
): Promise<ConfigSearchersInterface> => {
  const response = await api.put(
    '/searchers/' + searcher.primaryAddress,
    searcher
  );
  return response.data;
};

export const deleteSearcherAsync = async (
  primaryAddress: string
): Promise<void> => {
  const response = await api.delete('/searchers/' + primaryAddress);
  return response.data;
};

export const addSearcherEoaAsync = async (
  primaryAddress: string,
  eoa: AddSearcherEoaDto
): Promise<ConfigSearchersEoasInterface> => {
  const response = await api.post(`/searchers/${primaryAddress}/eoas`, eoa);
  return response.data;
};

export const deleteSearcherEoaAsync = async (
  primaryAddress: string,
  eoaAddress: string
): Promise<void> => {
  const response = await api.delete(
    `/searchers/${primaryAddress}/eoas/${eoaAddress}`
  );
  return response.data;
};

export const addSearcherNetworkAsync = async (
  primaryAddress: string,
  network: AddSearcherNetworksDto
): Promise<ConfigSearchersNetworksInterface> => {
  const response = await api.post(
    `/searchers/${primaryAddress}/networks`,
    network
  );
  return response.data;
};

export const deleteSearcherNetworkAsync = async (
  primaryAddress: string,
  networkId: number
): Promise<void> => {
  const response = await api.delete(
    `/searchers/${primaryAddress}/networks/${networkId}`
  );
  return response.data;
};

export const updateSearcherNetworkAsync = async (
  primaryAddress: string,
  network: UpdateSearcherNetworksDto
): Promise<ConfigSearchersNetworksInterface> => {
  const response = await api.put(
    `/searchers/${primaryAddress}/networks/${network.networkId}`,
    network
  );
  return response.data;
};
