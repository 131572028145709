import { Box, Paper } from '@mui/material';
import Blacklist from 'src/components//Blacklist/Blacklist';
import BroadcasterList from 'src/components//BroadcasterList/BroadcasterList';
import Title from 'src/components//Title/Title';
import TabsWithNavigation from 'src/components/TabsWithNavigation/TabsWithNavigation';

const tabs = [
  {
    label: 'Access',
    linkTo: '',
    component: <BroadcasterList />,
  },
  {
    label: 'Blacklist',
    linkTo: 'blacklist',
    component: <Blacklist />,
  },
];

const Broadcasters = (): JSX.Element => {
  return (
    <Box
      component={Paper}
      sx={{
        p: 2,
      }}>
      <Title>Broadcasters</Title>
      <TabsWithNavigation tabs={tabs} />
    </Box>
  );
};

export default Broadcasters;
