import { ConnectedSearchersInterface } from 'src/shared/interfaces/connected-searchers.interface';

import { getApiClient } from './base.api';

const api = getApiClient();

export const getConnectedSearchersAsync = async (): Promise<
  ConnectedSearchersInterface[]
> => {
  const response = await api.get('/connected-searchers');
  return response.data;
};
