import { BroadcastersInterface } from 'src/shared/interfaces/broadcasters.interface';
import { BroadcastersBlacklsitInterface } from 'src/shared/interfaces/broadcasters-blacklist.interface';

import { getApiClient } from './base.api';
import { AddBroadcasterDto } from './dto/add-broadcaster.dto';
import { AddToBlacklistDto } from './dto/add-to-blacklist.dto';
import { UpdateBroadcasterDto } from './dto/update-broadcaster.dto';
import { UpdateKolibrioEoaDto } from './dto/update-kolibrio-eoa.dto';

const api = getApiClient();

export const getBroadcastersAsync = async (): Promise<
  BroadcastersInterface[]
> => {
  const response = await api.get('/broadcasters');
  return response.data;
};

export const getBroadcasterByAddressAsync = async (
  address: string
): Promise<BroadcastersInterface> => {
  const response = await api.get('/broadcasters/' + address);
  return response.data;
};

export const addBroadcasterAsync = async (
  broadcaster: AddBroadcasterDto
): Promise<BroadcastersInterface> => {
  const response = await api.post('/broadcasters', broadcaster);
  return response.data;
};

export const updateBroadcasterAsync = async (
  broadcaster: UpdateBroadcasterDto
): Promise<BroadcastersInterface> => {
  const response = await api.put(
    '/broadcasters/' + broadcaster.address,
    broadcaster
  );
  return response.data;
};

export const updateBroadcasterEOAAsync = async (
  broadcaster: UpdateKolibrioEoaDto
): Promise<BroadcastersInterface> => {
  const response = await api.put(
    '/broadcasters/kolibrio_eoa/' + broadcaster.address,
    broadcaster
  );
  return response.data;
};

export const deleteBroadcasterAsync = async (
  address: string
): Promise<void> => {
  const response = await api.delete('/broadcasters/' + address);
  return response.data;
};

export const getBlacklistAsync = async (): Promise<
  BroadcastersBlacklsitInterface[]
> => {
  const response = await api.get('/blacklist');
  return response.data;
};

export const deleteBlacklistItemAsync = async (
  address: string
): Promise<void> => {
  const response = await api.delete('/blacklist/' + address);
  return response.data;
};

export const addToBlacklistAsync = async (
  blacklistItem: AddToBlacklistDto
): Promise<BroadcastersBlacklsitInterface> => {
  const response = await api.post('/blacklist', blacklistItem);
  return response.data;
};
