import { Search } from '@mui/icons-material';
import {
  FormControl,
  FormControlProps,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';

const SearchInput = (props: FormControlProps): JSX.Element => {
  return (
    <FormControl {...props}>
      <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
      <OutlinedInput
        type="text"
        endAdornment={
          <InputAdornment position="end">
            <IconButton edge="end">
              <Search />
            </IconButton>
          </InputAdornment>
        }
        label="Search"
      />
    </FormControl>
  );
};

export default SearchInput;
