import { ContentCopy, Delete } from '@mui/icons-material';
import {
  Avatar,
  Box,
  BoxProps,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Identicon from '@polkadot/react-identicon';
import { useMutation, useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { BroadcastersBlacklsitInterface } from 'src/shared/interfaces/broadcasters-blacklist.interface';

import {
  deleteBlacklistItemAsync,
  getBlacklistAsync,
} from '../../api/broadcasters.api';
import AddToBlacklistButton from '../AddToBlacklistButton/AddToBlacklistButton';
import AreYouSure from '../AreYouSure/AreYouSure';
import Copy from '../CopyToClipboard/CopyToClipboard';
import NoData from '../NoData/NoData';
import SearchInput from '../SearchInput/SearchInput';

const Blacklist = observer((props: BoxProps) => {
  const { data, isLoading, refetch, remove } = useQuery(
    ['blacklist'],
    getBlacklistAsync
  );

  useEffect(() => {
    return () => {
      remove();
    };
  }, [remove]);

  const [searchText, setSearchText] = useState('');
  const deleteQuery = useMutation(deleteBlacklistItemAsync, {
    onSuccess: () => {
      refetch();
    },
  });

  const handleDelete = async (address: string): Promise<void> => {
    deleteQuery.mutate(address);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value.toLowerCase());
  };

  let filtredData: BroadcastersBlacklsitInterface[] = [];
  if (!isLoading && !!data) {
    filtredData = data.filter(
      (item: BroadcastersBlacklsitInterface) =>
        item.address.toLowerCase().indexOf(searchText) >= 0 ||
        (item.description || '').toLowerCase().indexOf(searchText) >= 0
    );
  }

  return (
    <Box {...props}>
      <Box
        display="flex"
        justifyContent={'space-between'}
        sx={{
          mb: 1,
        }}>
        <AddToBlacklistButton
          size="small"
          variant="contained"
          onSuccess={refetch}
          sx={{
            minWidth: '100px',
          }}
        />
        <SearchInput
          size={'small'}
          sx={{
            width: {
              md: '300px',
              xs: '100%',
            },
          }}
          onChange={handleSearch}
        />
      </Box>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Address</TableCell>
              <TableCell>Description</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filtredData.length > 0 ? (
              filtredData.map((item: BroadcastersBlacklsitInterface) => (
                <TableRow
                  key={item.address}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell width={70}>
                    <Avatar alt={item.address} sx={{ textAlign: 'center' }}>
                      <Identicon
                        value={item.address}
                        size={38}
                        theme={'ethereum'}
                      />
                    </Avatar>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '450px',
                    }}>
                    <Copy text={item.address}>
                      {item.address}{' '}
                      <ContentCopy sx={{ fontSize: 'inherit' }} />
                    </Copy>
                  </TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell align="right">
                    <AreYouSure onClick={() => handleDelete(item.address)}>
                      <IconButton edge="end" aria-label="delete">
                        <Delete />
                      </IconButton>
                    </AreYouSure>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={4}>
                <NoData sx={{ m: 1 }} />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});

export default Blacklist;
