import './FakeSearcher.scss';

import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  WarningOutlined,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import { BoxProps } from '@mui/system';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
// eslint-disable-next-line no-restricted-imports
import * as ethers from 'ethers';
import { ChangeEvent, useEffect, useState } from 'react';
import { networkNamesConfig } from 'src/shared/configs/network-names.config';
import { FakeSearcherNetworkInterface } from 'src/shared/interfaces/fake-searcher-network.interface';

import {
  getBalanceAsync,
  getNetworksAsync,
  updateNetworkAsync,
} from '../../api/fakeSearcher.api';
import FakeSearcherWhitelist from '../FakeSearcherWhitelist/FakeSearcherWhitelist';
import NoData from '../NoData/NoData';

type RowProps = {
  row: FakeSearcherNetworkInterface;
  onSwitchChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSuccess: () => void;
};

const Row = (props: RowProps): JSX.Element => {
  const { row, onSwitchChange, onSuccess } = props;
  const [open, setOpen] = useState(false);
  const networkName = networkNamesConfig[String(row.networkId)];

  return (
    <>
      <TableRow className="collapsible-row">
        <TableCell sx={{ px: 1.5, pr: 0, py: 0, m: 0 }}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{networkName || row.networkId}</TableCell>
        <TableCell align="right">{row.tips}</TableCell>
        <TableCell align="right">
          <Balance networkId={row.networkId} minBalance={row.tips * 2} />
        </TableCell>
        <TableCell align="center">{row.whitelist.length}</TableCell>
        <TableCell align="right">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Stack direction="row" spacing={0}>
              <Switch
                checked={row.isActive}
                onChange={onSwitchChange}
                name={'isActive'}
                value={row.networkId}
              />
            </Stack>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <FakeSearcherWhitelist
                networkId={row.networkId}
                whitelist={row.whitelist}
                onSuccess={onSuccess}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

type BalanceProps = {
  networkId: number;
  minBalance?: number;
};

const Balance = (props: BalanceProps): JSX.Element => {
  const { networkId, minBalance } = props;

  const { data, isLoading, remove } = useQuery(
    ['fakeSearcherBalance', networkId],
    () => getBalanceAsync(networkId)
  );

  useEffect(() => {
    return () => {
      remove();
    };
  }, [remove]);

  let balance = 0;
  if (data) {
    balance = +(+ethers.utils.formatUnits(String(data), 18)).toFixed(6);
  }

  return (
    <>
      {isLoading ? (
        <CircularProgress
          color={'inherit'}
          size={18}
          sx={{
            fontSize: '12px',
            p: 0,
            m: 0,
            position: 'relative',
            top: '3px',
          }}
          disableShrink
        />
      ) : (
        <>
          {!!minBalance && balance < minBalance && (
            <Tooltip title="The balance is too low!">
              <WarningOutlined
                color={'warning'}
                sx={{
                  fontSize: '14px',
                  position: 'relative',
                  top: '2px',
                  mr: '3px',
                }}
              />
            </Tooltip>
          )}
          {balance}
        </>
      )}
    </>
  );
};

const FakeSearcher = (props: BoxProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');

  const { data, refetch, remove } = useQuery(
    ['fakeSearcher'],
    getNetworksAsync
  );

  useEffect(() => {
    return () => {
      remove();
    };
  }, [remove]);

  const updateNetworkQuery = useMutation(updateNetworkAsync, {
    onSuccess: () => {
      refetch();
    },
    onError: (error: AxiosError) => {
      const errorData = error?.response?.data as
        | { message?: string }
        | undefined;

      if (errorData?.message) {
        setErrorMessage(errorData.message);
      } else {
        setErrorMessage('Failed to update fake searcher config');
      }
    },
  });

  const onSwitchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setErrorMessage('');
    const { name, checked, value } = event.target;
    updateNetworkQuery.mutate({
      networkId: +value,
      [name]: checked,
    });
  };

  return (
    <Box className="fake-searcher-network-list" {...props}>
      {!!errorMessage?.length && (
        <Alert
          severity="error"
          sx={{ mb: '10px' }}
          onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}
      <Box
        display="flex"
        justifyContent={'flex-end'}
        sx={{
          mb: 1,
        }}>
        <TextField
          label="Searcher address"
          value="0x1Bb9A54e9fD747DF96070B2450484dc182B978a9"
          variant="outlined"
          size="small"
          sx={{ width: '410px' }}
        />
      </Box>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '50px' }} />
              <TableCell>Network</TableCell>
              <TableCell align="right">Tip amount</TableCell>
              <TableCell align="right">Current balance</TableCell>
              <TableCell align="center">Whitelisted addresses</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length > 0 ? (
              data.map((row: FakeSearcherNetworkInterface) => (
                <Row
                  key={row.networkId}
                  row={row}
                  onSwitchChange={onSwitchChange}
                  onSuccess={refetch}
                />
              ))
            ) : (
              <TableCell colSpan={5}>
                <NoData sx={{ m: 1 }} />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FakeSearcher;
