import { Button, ButtonProps } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { logoutUserAsync } from '../../../../api/auth.api';

const LogoutButton = (props: ButtonProps): JSX.Element => {
  const { mutate: logoutUser } = useMutation(
    async () => await logoutUserAsync(),
    {
      onSuccess: () => {
        window.location.href = '/login';
      },
    }
  );

  const onLogoutHandler = async (): Promise<void> => {
    logoutUser();
  };

  return (
    <Button onClick={onLogoutHandler} {...props}>
      Logout
    </Button>
  );
};

export default LogoutButton;
