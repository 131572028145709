import './NavBar.scss';

import {
  CurrencyExchange,
  Groups3,
  Home,
  QueryStats,
  Settings,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  BoxProps,
  Button,
  Container,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';

import { useStores } from '../../../../store/RootStore';
import LogoutButton from '../LogoutButton/LogoutButton';

const pages = [
  {
    name: 'Home',
    link: '/',
    icon: <Home fontSize="small" />,
  },
  {
    name: 'Broadcasters',
    link: '/broadcasters',
    icon: <Groups3 fontSize="small" />,
  },
  {
    name: 'Searchers',
    link: '/searchers',
    icon: <QueryStats fontSize="small" />,
  },
  {
    name: 'Arbitrages',
    link: '/arbitrages',
    icon: <CurrencyExchange fontSize="small" />,
  },
];

const NavBar = observer(() => {
  const {
    AppStore: { user },
  } = useStores();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (): void => {
    setAnchorElNav(null);
  };

  const LogoBox = (props: BoxProps): JSX.Element => {
    return (
      <Box className="logo-box" {...props}>
        <Link to={'/'}>
          <Box
            component="img"
            sx={{
              maxHeight: '35px',
            }}
            alt="Kolibrio"
            src="/logo.png"
          />
          <Box component={'span'}>console</Box>
        </Link>
      </Box>
    );
  };

  return (
    <AppBar className="nav-bar">
      <Container sx={{ maxWidth: '1300px' }} maxWidth="xl">
        <Toolbar>
          <LogoBox
            className={'logo-box'}
            sx={{
              flexGrow: 0,
              display: {
                xs: 'none',
                md: 'flex',
                position: 'relative',
              },
            }}
          />
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page, i) => (
              <Button
                key={'nav-button-' + i}
                size="large"
                sx={{ display: 'block', fontWeight: 'bold' }}
                component={Link}
                to={page.link}
                color="inherit">
                {page.name}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}>
              {pages.map((page, i) => (
                <MenuItem
                  key={'menu-item-' + i}
                  component={Link}
                  to={page.link}>
                  <ListItemIcon>{page.icon}</ListItemIcon>
                  <ListItemText>{page.name}</ListItemText>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex' }}>
            {user ? (
              <>
                <IconButton
                  key={'settings'}
                  size="large"
                  aria-label="console settings"
                  color="inherit"
                  component={Link}
                  to="/settings">
                  <Settings />
                </IconButton>
                <LogoutButton
                  key={'logout'}
                  size="large"
                  sx={{ display: 'block', fontWeight: 'bold' }}
                  color="inherit"
                />
              </>
            ) : (
              <Button
                key={'login'}
                size="large"
                sx={{ display: 'block', fontWeight: 'bold' }}
                component={Link}
                to={'/login'}
                color="inherit">
                Login
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
});

export default NavBar;
