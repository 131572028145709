import './BroadcasterList.scss';

import { ContentCopy, Delete } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Box,
  BoxProps,
  Checkbox,
  IconButton,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import Identicon from '@polkadot/react-identicon';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { BroadcastersInterface } from 'src/shared/interfaces/broadcasters.interface';

import {
  deleteBroadcasterAsync,
  getBroadcastersAsync,
  updateBroadcasterAsync,
} from '../../api/broadcasters.api';
import AddNewBroadcasterButton from '../AddNewBroadcasterButton/AddNewBroadcasterButton';
import AreYouSure from '../AreYouSure/AreYouSure';
import Copy from '../CopyToClipboard/CopyToClipboard';
import EditBroadcasterConfigButton from '../EditBroadcasterConfigButton/EditBroadcasterConfigButton';
import EditBroadcasterKolibrioEoaButton from '../EditBroadcasterKolibrioEoaButton/EditBroadcasterKolibrioEoaButton';
import NoData from '../NoData/NoData';
import SearchInput from '../SearchInput/SearchInput';

const BroadcasterList = (props: BoxProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');
  const [searchText, setSearchText] = useState('');

  const { data, isLoading, refetch, remove } = useQuery(
    ['broadcasters'],
    getBroadcastersAsync
  );

  useEffect(() => {
    return () => {
      remove();
    };
  }, [remove]);

  const deleteQuery = useMutation(deleteBroadcasterAsync, {
    onSuccess: () => {
      refetch();
    },
    onError: () => {
      setErrorMessage('Failed to remove broadcaster config');
    },
  });

  const updateBroadcasterConfigQuery = useMutation(updateBroadcasterAsync, {
    onSuccess: () => {
      refetch();
    },
    onError: (error: AxiosError) => {
      const errorData = error?.response?.data as
        | { message?: string }
        | undefined;

      if (errorData?.message) {
        setErrorMessage(errorData.message);
      } else {
        setErrorMessage('Failed to update broadcaster config');
      }
    },
  });

  const handleDelete = async (address: string): Promise<void> => {
    setErrorMessage('');
    deleteQuery.mutate(address);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value.toLowerCase());
  };

  const onSwitchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setErrorMessage('');
    const { name, checked, value } = event.target;
    updateBroadcasterConfigQuery.mutate({
      address: value,
      [name]: checked,
    });
  };

  let filtredData: BroadcastersInterface[] = [];
  if (!isLoading && !!data) {
    filtredData = data.filter(
      (item: BroadcastersInterface) =>
        item.address.toLowerCase().indexOf(searchText) >= 0 ||
        (item.description || '').toLowerCase().indexOf(searchText) >= 0 ||
        item.token.toLowerCase().indexOf(searchText) >= 0
    );
  }

  return (
    <Box className="broadcasters-list" {...props}>
      {!!errorMessage?.length && (
        <Alert
          severity="error"
          sx={{ mb: '10px' }}
          onClose={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}
      <Box
        display="flex"
        justifyContent={'space-between'}
        sx={{
          mb: 1,
        }}>
        <AddNewBroadcasterButton
          size="small"
          variant="contained"
          onSuccess={refetch}
          sx={{
            minWidth: '100px',
          }}
        />
        <SearchInput
          size="small"
          sx={{
            width: {
              md: '300px',
              xs: '100%',
            },
          }}
          onChange={handleSearch}
        />
      </Box>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>Address</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="center">Allow Use Gateway</TableCell>
              <TableCell align="center">Edit Kolibrio EOA</TableCell>
              <TableCell align="center">Edit Config</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filtredData.length > 0 ? (
              filtredData.map((item: BroadcastersInterface) => (
                <TableRow
                  className={'table-row' + (!item.isActive ? ' unactive' : '')}
                  key={item.address}>
                  <TableCell width={70}>
                    <Avatar alt={item.address} sx={{ textAlign: 'center' }}>
                      <Identicon
                        value={item.address}
                        size={38}
                        theme={item.isActive ? 'ethereum' : 'empty'}
                      />
                    </Avatar>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '400px',
                    }}>
                    <Box display="flex" flexDirection="column">
                      <Copy text={item.address}>
                        {item.address}{' '}
                        <ContentCopy sx={{ fontSize: 'inherit' }} />
                      </Copy>
                      <Tooltip title="Access token">
                        <b>
                          <Copy text={item.token}>
                            <i style={{ opacity: '0.4' }}>
                              {item.token}{' '}
                              <ContentCopy sx={{ fontSize: 'inherit' }} />
                            </i>
                          </Copy>
                        </b>
                      </Tooltip>
                    </Box>
                    {/* <Copy text={item.address}>{item.address} <ContentCopy sx={{ fontSize: 'inherit' }} /></Copy> */}
                  </TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell align="center">
                    <Checkbox
                      checked={item.allowUseGateway}
                      onChange={onSwitchChange}
                      name={'allowUseGateway'}
                      value={item.address}
                      disabled={!item.isActive}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <EditBroadcasterKolibrioEoaButton
                      onSuccess={refetch}
                      broadcaster={item}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <EditBroadcasterConfigButton
                      onSuccess={refetch}
                      broadcaster={item}
                    />
                  </TableCell>

                  <TableCell align="right">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Stack direction="row" spacing={0}>
                        <Switch
                          checked={item.isActive}
                          onChange={onSwitchChange}
                          name={'isActive'}
                          value={item.address}
                        />
                      </Stack>
                      <AreYouSure onClick={() => handleDelete(item.address)}>
                        <IconButton edge="end" sx={{ m: 0 }}>
                          <Delete />
                        </IconButton>
                      </AreYouSure>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableCell colSpan={6}>
                <NoData sx={{ m: 1 }} />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BroadcasterList;
