import { Box, Paper } from '@mui/material';
import Title from 'src/components//Title/Title';
import FakeSearcher from 'src/components/FakeSearcher/FakeSearcher';
import SearcherList from 'src/components/SearcherList/SearcherList';
import TabsWithNavigation from 'src/components/TabsWithNavigation/TabsWithNavigation';

const tabs = [
  {
    label: 'Access',
    linkTo: '',
    component: <SearcherList />,
  },
  {
    label: 'Fake searcher',
    linkTo: 'fake-searcher',
    component: <FakeSearcher />,
  },
];

const Searchers = (): JSX.Element => {
  return (
    <Box
      component={Paper}
      sx={{
        p: 2,
      }}>
      <Title>Searchers</Title>
      <TabsWithNavigation tabs={tabs} />
    </Box>
  );
};

export default Searchers;
