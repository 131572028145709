import { Box, Button, ButtonProps, Modal, Typography } from '@mui/material';
import { useState } from 'react';

import { BroadcastersInterface } from '../../shared/interfaces/broadcasters.interface';
import EditBroadcasterConfig from '../EditBroadcasterConfig/EditBroadcasterConfig';

type EditBroadcasterConfigButtonProps = ButtonProps & {
  onSuccess?: () => void;
  broadcaster: BroadcastersInterface;
};

const EditBroadcasterConfigButton = (
  props: EditBroadcasterConfigButtonProps
): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = (): void => setOpenModal(true);
  const handleClose = (): void => setOpenModal(false);

  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
  };

  return (
    <>
      <Button {...props} onClick={handleOpen}>
        Edit
      </Button>
      <Modal open={openModal} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h2" align="center" sx={{ mb: 3 }}>
            Edit config
          </Typography>
          <EditBroadcasterConfig
            broadcaster={props.broadcaster}
            onSuccess={() => {
              if (props.onSuccess) {
                props.onSuccess();
              }
              handleClose();
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default EditBroadcasterConfigButton;
