import { Alert, Box, Snackbar } from '@mui/material';
import { ReactNode, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Copy = (props: { text: string; children: ReactNode }): JSX.Element => {
  const [open, setOpen] = useState(false);

  const onCopy = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <Box
      component={'span'}
      sx={{
        cursor: 'pointer',
      }}>
      <CopyToClipboard text={props.text} onCopy={onCopy}>
        <span>{props.children}</span>
      </CopyToClipboard>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        message="Copied!"
        key={'copy-to-clipboard-message'}
        autoHideDuration={3000}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Copied!
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Copy;
