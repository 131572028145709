export enum ChainExplorersEnum {
  'https://etherscan.io/' = 1,
  'https://goerli.etherscan.io/' = 5,
  'https://bscscan.com/' = 56,
  'https://testnet.bscscan.com/' = 97,
  'https://explorer.aurora.dev/' = 1313161554,
  'https://explorer.testnet.aurora.dev/' = 1313161555,
  'https://polygonscan.com/' = 137,
  'https://snowtrace.io/' = 43114,
}
