import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { observer } from 'mobx-react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import Layout from './components/Layout/Layout';
import Arbitrages from './pages/Arbitrages/Arbitrages';
import Broadcasters from './pages/Broadcasters/Broadcasters';
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Login/Login';
import NotFound from './pages/NotFound/NotFound';
import Searchers from './pages/Searchers/Searchers';
import Settings from './pages/Settings/Settings';
import { useStores } from './store/RootStore';

const mdTheme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: '#fff',
          color: '#000',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#4889ff',
    },
  },
  typography: {
    fontFamily: ['Roboto', '"Helvetica Neue"'].join(','),
    h1: {
      fontSize: 26,
      fontWeight: 500,
    },
    h2: {
      fontSize: 22,
      fontWeight: 500,
    },
    h3: {
      fontSize: 18,
      fontWeight: 500,
    },
    h4: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 'normal',
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 'normal',
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 300,
    },
  },
});

const App = observer(() => {
  const { AppStore } = useStores();
  const location = useLocation();

  if (!AppStore.user && location.pathname !== '/login') {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  return (
    <ThemeProvider theme={mdTheme}>
      <CssBaseline />
      <Layout>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/broadcasters/*" element={<Broadcasters />} />
          <Route path="/searchers/*" element={<Searchers />} />
          <Route path="/arbitrages" element={<Arbitrages />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </ThemeProvider>
  );
});

export default App;
