import { Box, Grid } from '@mui/material';

import ConnectedSearchersWidget from '../../components/ConnectedSearchersWidget/ConnectedSearchersWidget';

const Dashboard = (): JSX.Element => {
  return (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={4}>
        <Grid item xs={12} md={12}>
          <ConnectedSearchersWidget />
        </Grid>
        {/* <Grid item xs={12} md={4} >

                </Grid> */}
      </Grid>
    </Box>
  );
};

export default Dashboard;
