import { Box, Paper } from '@mui/material';
import Title from 'src/components//Title/Title';
import TabsWithNavigation from 'src/components/TabsWithNavigation/TabsWithNavigation';

const tabs = [
  {
    label: 'Console users',
    linkTo: '',
    component: <>Console users...</>,
  },
];

const Settings = (): JSX.Element => {
  return (
    <Box
      component={Paper}
      sx={{
        p: 2,
      }}>
      <Title>Settings</Title>
      <TabsWithNavigation tabs={tabs} />
    </Box>
  );
};

export default Settings;
